import { Controller } from "@hotwired/stimulus"
import { useResize, useDebounce } from "stimulus-use"

export default class extends Controller {
  static values = { fillWith: String }
  static debounces = ["fill"]

  connect() {
    useDebounce(this)
    useResize(this, { dispatchEvent: false })
  }

  resize() {
    this.fill()
  }

  fill() {
    if (this.hidden) return
    if (!this.fillWith) return
    do {
      this.element.append(this.fillWith)
    } while (!this.overflowing)
    this.trimOverflowingElements()
    this.dispatch("filled")
  }

  trimOverflowingElements() {
    if (!this.overflowing) return
    do {
      if (!this.element.lastChild) break
      this.element.removeChild(this.element.lastChild)
    } while (this.overflowing)
  }

  get fillWith() {
    if (!this.hasFillWithValue) return
    const parser = new DOMParser()
    return parser.parseFromString(this.fillWithValue, "text/html").body
      .firstChild
  }

  get text() {
    return this.element.textContent
  }

  set text(value) {
    this.element.textContent = value
  }

  get overflowing() {
    return (
      this.element.scrollWidth > this.width ||
      this.element.scrollWidth > window.innerWidth
    )
  }

  get width() {
    return Math.ceil(this.element.getBoundingClientRect().width)
  }

  get hidden() {
    return this.element.offsetParent === null
  }
}
