class WeatherStatus {
  static get INDETERMINATE() {
    return "indeterminate"
  }

  static get CATEGORIES() {
    return {
      clear: [
        "a few clouds",
        "clear (night)",
        "clearing",
        "mix of sun and cloud",
        "mostly clear",
        "mostly cloudy",
        "mostly sunny",
        "partly cloudy",
        "partly sunny",
        "sunny"
      ],
      wind: [
        "blustery",
        "breezy",
        "snow and blowing snow",
        "storm force winds",
        "strong winds",
        "wind advisory warning",
        "windy"
      ],
      precipitation: [
        "(slight) chance of rain showers",
        "(slight) chance of rain showers",
        "(slight) chance of thunderstorms",
        "a few flurries mixed with ice pellets",
        "a few flurries or rain showers",
        "a few flurries or thundershowers",
        "a few flurries",
        "a few rain showers or flurries",
        "a few rain showers or wet flurries",
        "a few showers or drizzle",
        "a few wet flurries",
        "blizzard",
        "chance of drizzle or rain",
        "chance of flurries (night and day)",
        "chance of freezing rain or rain",
        "chance of freezing rain",
        "chance of precipitation (20%)",
        "chance of precipitation (30%)",
        "chance of precipitation (40%)",
        "chance of precipitation (50%)",
        "chance of precipitation (60%)",
        "chance of precipitation (70%)",
        "chance of precipitation (80%)",
        "chance of precipitation (90%)",
        "chance of precipitation (100%)",
        "chance of rain and snow showers (20%)",
        "chance of rain and snow showers (30%)",
        "chance of rain and snow showers (40%)",
        "chance of rain and snow showers (50%)",
        "chance of rain and snow showers (60%)",
        "chance of rain and snow showers (70%)",
        "chance of rain and snow showers (80%)",
        "chance of rain and snow showers (90%)",
        "chance of rain and snow showers (100%)",
        "chance of rain and snow showers",
        "chance of rain showers (100%)",
        "chance of rain showers (20%)",
        "chance of rain showers (30%)",
        "chance of rain showers (40%)",
        "chance of rain showers (50%)",
        "chance of rain showers (60%)",
        "chance of rain showers (70%)",
        "chance of rain showers (80%)",
        "chance of rain showers (90%)",
        "chance of showers (night and day)",
        "chance of showers at times heavy or thundershowers",
        "chance of showers at times heavy or thunderstorms",
        "chance of showers or flurries (night and day)",
        "chance of showers or thundershowers",
        "chance of snow mixed with freezing rain",
        "chance of snow showers",
        "chance of snow",
        "chance of thundershowers",
        "chance of thunderstorms",
        "chance rain showers",
        "cloudy periods (night)",
        "cloudy with chance of showers",
        "cloudy with isolated showers",
        "cloudy",
        "drizzle or freezing drizzle",
        "drizzle",
        "flood advisory warning",
        "fog developing",
        "fog dissipating",
        "fog patches",
        "fog",
        "freezing drizzle",
        "freezing rain",
        "haze",
        "ice fog developing",
        "ice fog dissipating",
        "ice fog",
        "ice pellets mixed with freezing rain",
        "ice pellets mixed with snow",
        "ice pellets or snow",
        "ice pellets",
        "increasing cloudiness",
        "isolated rain and snow showers",
        "isolated rain showers",
        "isolated snow showers",
        "light snow",
        "local snow squalls",
        "mainly cloudy",
        "mostly cloudy",
        "near blizzard",
        "overcast",
        "patchy fog",
        "patchy blowing snow",
        "periods of rain or snow",
        "periods of snow",
        "possibility of drizzle mixed with freezing drizzle",
        "possibility of drizzle",
        "rain and snow showers likely",
        "rain at times heavy",
        "rain showers likely",
        "rain",
        "scattered rain and snow showers",
        "scattered rain showers",
        "scattered showers",
        "scattered snow showers",
        "scattered sprinkles/chance of sprinkles",
        "showers and thunderstorms likely",
        "showers",
        "smoke",
        "smoky",
        "snow and blizzard",
        "snow at times heavy and blowing snow",
        "snow at times heavy",
        "snow mixed with rain",
        "snow squalls",
        "wet flurries",
        "wet snow",
        "winter storm warning"
      ]
    }
  }

  static fromForecast(forecast) {
    if (forecast === null || forecast === undefined) {
      return WeatherStatus.INDETERMINATE
    }

    for (const category in WeatherStatus.CATEGORIES) {
      if (WeatherStatus.CATEGORIES[category].includes(forecast.toLowerCase())) {
        return category
      }
    }

    return WeatherStatus.INDETERMINATE
  }
}

export default WeatherStatus
