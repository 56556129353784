import { Controller } from "@hotwired/stimulus"
import { useResize } from "stimulus-use"

export default class extends Controller {
  static classes = ["playing"]
  static targets = ["audio", "range", "progress", "dash", "timer"]
  static values = {
    paused: { default: true, type: Boolean },
    duration: { default: 0, type: Number },
    currentTime: { default: 0, type: Number },
    progress: { default: 0, type: Number }
  }

  connect() {
    this.isScrubbing = false
    useResize(this)
  }

  dashTargetConnected() {
    this.updateHeadPosition()
  }

  resize() {
    this.updateHeadPosition()
  }

  toggle() {
    if (this.element.classList.contains(this.playingClass)) {
      this.pause()
    } else {
      this.play()
    }
  }

  play() {
    this.element.classList.add(this.playingClass)
    this.audioTarget.play()
  }

  pause() {
    this.element.classList.remove(this.playingClass)
    this.audioTarget.pause()
  }

  scrubbing(event) {
    this.isScrubbing = true
    this.progressValue = parseInt(event.target.value)
  }

  scrubbed(event) {
    this.audioTarget.currentTime = event.target.value
    this.isScrubbing = false
  }

  onEnded() {
    this.element.classList.remove(this.playingClass)
    this.dispatch("ended")
  }

  onLoadedmetadata(event) {
    this.durationValue = parseInt(event.target.duration)
  }

  onTimeupdate(event) {
    if (this.isScrubbing) return
    this.dispatch("timeupdate")
    this.progressValue = parseInt(event.target.currentTime)
  }

  progressValueChanged() {
    this.rangeTarget.value = this.progressValue
    this.updateHeadPosition()
    if (this.#timerController)
      this.#timerController.currentValue = this.progressValue
  }

  durationValueChanged() {
    this.rangeTarget.max = this.durationValue
    this.updateHeadPosition()
  }

  updateHeadPosition() {
    const index = this.headIndex
    this.dashTargets.forEach((dash, i) => {
      dash.classList.toggle("head", i === index)
    })
    this.element.style.setProperty("--head-position", index)
  }

  get headIndex() {
    return Math.max(
      Math.min(
        Math.floor(this.dashTargets.length * this.percentageProgress),
        this.dashTargets.length - 1
      ),
      0
    )
  }

  get playing() {
    return this.element.classList.contains(this.playingClass)
  }

  get percentageProgress() {
    return this.progressValue / this.durationValue
  }

  get #timerController() {
    return this.application.getControllerForElementAndIdentifier(
      this.timerTarget,
      "audio-player--timer"
    )
  }
}
