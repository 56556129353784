import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player"]

  next(event) {
    const nextPlayer = this.#getNextPlayer(event.target)
    if (nextPlayer) {
      this.#controllerForPlayer(nextPlayer).play()
    }
  }

  #getNextPlayer(player) {
    const currentPlayerIndex = this.#getIndexOfPlayer(player)
    return this.playerTargets[currentPlayerIndex + 1]
  }

  #getIndexOfPlayer(player) {
    return this.playerTargets.findIndex((target) => target === player)
  }

  #controllerForPlayer(player) {
    return this.application.getControllerForElementAndIdentifier(
      player,
      "audio-player"
    )
  }
}
