
        import * as module0 from '$components/audio_player.css';import * as module1 from '$components/audio_player/scrubber.css';import * as module2 from '$components/audio_player/timer.css';import * as module3 from '$components/audio_player/timer/timestamp.css';import * as module4 from '$components/audio_player/timer_controller.js';import * as module5 from '$components/audio_player_controller.js';import * as module6 from '$components/audio_playlist_controller.js';import * as module7 from '$components/character_filler_controller.js';import * as module8 from '$components/current_time_controller.js';import * as module9 from '$components/layout/stack.css';import * as module10 from '$components/layout/text.css';import * as module11 from '$components/read_more.css';import * as module12 from '$components/read_more_controller.js';import * as module13 from '$components/report.css';import * as module14 from '$components/report_controller.js';import * as module15 from '$components/site/footer.css';import * as module16 from '$components/site/header.css';import * as module17 from '$components/svg_text/character.css';import * as module18 from '$components/svg_text/word.css'
        const modules = {
            "audio_player.css": module0,
            "audio_player/scrubber.css": module1,
            "audio_player/timer.css": module2,
            "audio_player/timer/timestamp.css": module3,
            "audio_player/timer_controller.js": module4,
            "audio_player_controller.js": module5,
            "audio_playlist_controller.js": module6,
            "character_filler_controller.js": module7,
            "current_time_controller.js": module8,
            "layout/stack.css": module9,
            "layout/text.css": module10,
            "read_more.css": module11,
            "read_more_controller.js": module12,
            "report.css": module13,
            "report_controller.js": module14,
            "site/footer.css": module15,
            "site/header.css": module16,
            "svg_text/character.css": module17,
            "svg_text/word.css": module18,
        };
        export default modules;
      