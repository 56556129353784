import { Controller } from "@hotwired/stimulus"
import WeatherStatus from "$javascript/weather_status"

export default class extends Controller {
  static targets = ["character"]
  static values = {
    weather: String,
    forecast: String,
    grid: Object,
    forecastEndpoint: String
  }
  static classes = ["debug"]
  maxRetries = 3
  retryDelay = 1000

  connect() {
    this.drawnCharacters = []
    this.getWeather()
  }

  debug() {
    this.element.classList.toggle(this.debugClass)
  }

  async getWeather() {
    let currentDelay = this.retryDelay
    for (let attempt = 0; attempt <= this.maxRetries; attempt++) {
      try {
        const response = await fetch(this.forecastEndpointValue, {
          mode: "no-cors",
          headers: {
            Accept: "application/json"
          }
        })

        if (!response.ok) {
          this.forecastValue = "No forecast value available"
        } else {
          const json = await response.json()
          this.forecastValue = json.shortForecast
          break
        }
      } catch (error) {
        console.error(`Attempt ${attempt} failed: ${error.message}`)
        if (attempt < this.maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, currentDelay))
          currentDelay *= 2
        } else {
          throw new Error(
            `All attempts to fetch weather data failed: ${error.message}`
          )
        }
      }
    }
  }

  forecastValueChanged() {
    if (!this.hasForecastValue) return
    this.weatherValue = WeatherStatus.fromForecast(this.forecastValue)
  }

  weatherValueChanged() {
    this.draw()
  }

  draw() {
    this.drawnCharacters = []
    switch (this.weatherValue) {
      case "clear":
        this.#clear()
        break
      case "wind":
        this.#wind()
        break
      case "precipitation":
        this.#precipitation()
        break
      case "indeterminate":
        this.#indeterminate()
        break
    }
  }

  #clear() {
    this.characterTargets.forEach((character) => {
      character.querySelectorAll("path").forEach((path) => {
        path.style.transform = ""
      })
    })
  }

  #wind() {
    this.characterTargets.forEach((character) => {
      if (this.#characterIsDrawn(character)) return
      this.#windCharacter(character)
      this.drawnCharacters.push(character)
    })
  }

  #windCharacter(character) {
    character.querySelectorAll("path").forEach((path) => {
      const scaleX = this.#randomFloat(1.0, 2.0)
      const scaleY = this.#randomFloat(0.3, 1.0)
      const translateX = this.#randomFloat(-25.0, 25.0)
      const translateY = this.#randomFloat(0.0, 0.0)
      path.style.transform = `scale(${scaleX}, ${scaleY}) translate(${translateX}%, ${translateY}%)`
    })
  }

  #precipitation() {
    this.characterTargets.forEach((character) => {
      if (this.#characterIsDrawn(character)) return
      this.#precipitationCharacter(character)
      this.drawnCharacters.push(character)
    })
  }

  #precipitationCharacter(character) {
    character.querySelectorAll("path").forEach((path) => {
      const scale = this.#randomFloat(1.0, 2.5)
      path.style.transform = `scale(${scale})`
    })
  }

  #indeterminate() {
    this.characterTargets.forEach((character, i) => {
      if (this.#characterIsDrawn(character)) return
      if (i % 3 === 0) {
        this.#precipitationCharacter(character)
      } else if (i % 3 === 1) {
        this.#windCharacter(character)
      }
      this.drawnCharacters.push(character)
    })
  }

  #randomFloat(min, max) {
    return Math.random() * (max - min) + min
  }

  #characterIsDrawn(character) {
    return this.drawnCharacters.includes(character)
  }
}
