import { Controller } from "@hotwired/stimulus"
import moment from "moment-timezone"

export default class extends Controller {
  connect() {
    this.element.textContent = moment()
      .tz("America/New_York")
      .format("hmm A z ddd MMM D YYYY")
  }
}
