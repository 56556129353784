import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["current", "duration", "character"]
  static values = {
    current: { default: 0, type: Number },
    characters: Object
  }

  currentValueChanged() {
    if (this.#currentTimestamp === this.#currentValueTimestamp) return
    const newTimestampCharacters = this.#currentValueTimestamp.split("")
    const oldTimestampCharacters = this.#currentTimestamp.split("")
    newTimestampCharacters.forEach((character, i) => {
      if (character === oldTimestampCharacters[i]) return
      const newCharacter = this.#characterToElement(character)
      this.characterTargets[i].replaceWith(newCharacter)
    })
    this.dispatch("update")
  }

  #characterToElement(character) {
    return this.#stringToElement(this.charactersValue[`${character}`])
  }

  #stringToElement(string) {
    return document.createRange().createContextualFragment(string)
  }

  get #currentTimestamp() {
    return this.currentTarget.textContent.replaceAll(/\s/g, "")
  }

  get #currentValueTimestamp() {
    return new Date(this.currentValue * 1000).toISOString().substring(14, 19)
  }
}
